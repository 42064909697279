export default defineNuxtRouteMiddleware((to) => {
  const { user } = useUser();

  if (!user.value) {
    return navigateTo({
      name: ROUTE_NAMES.LOGIN,
      query: { redirect: to.path, ...to.query },
      replace: true,
    });
  }
});
